import React, { useEffect, useState } from 'react';

const JsonViewer = ({ jsonData }) => {
  const [formattedJson, setFormattedJson] = useState('');

  useEffect(() => {
    try {
      // If jsonData is a string, parse it first
      const parsed = typeof jsonData === 'string' ? JSON.parse(jsonData) : jsonData;
      // Beautify with 2-space indentation
      const prettyJson = JSON.stringify(parsed, null, 2);
      setFormattedJson(prettyJson);
    } catch (err) {
      setFormattedJson('Invalid JSON');
    }
  }, [jsonData]);

  return (
    <pre
      style={{
        color: 'white',
        padding: '12px',
        borderRadius: '8px',
        minHeight: '200px',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        overflowX: 'auto',
      }}
    >
      {formattedJson}
    </pre>
  );
};

export default JsonViewer;
