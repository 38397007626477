import React, { useState, useContext, useEffect, useCallback } from 'react'
import { Context as AppContext } from "../context/AppContext";
import { Box, Stack, Card, Typography, Button, IconButton, CircularProgress, Dialog, Grid, TextField, ToggleButton, FormControlLabel, Switch, Select, MenuItem, FormControl, Alert, Snackbar, Menu, InputLabel } from "@mui/material"
import Drawer from "../components/drawer"
import Timeline from "../components/timeline"
import Container from "../components/container"
import { useParams } from "react-router-dom"
import GoogleMapReact from "google-map-react";
import { AscDateArray, convertJsonToCsv, getSocDetails } from "../utils/inverter"
import {
    buttonData,
    data1,
    dataAddOn1,
} from "../data/inverterData";
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { getInverter, getInverterFlow, getBatteryFlow, getInverterDailyStatus, getImportPrices, getExportPrices, getCustomerDetails, UpdateDeviceInfo, getDeviceLogs, getDeviceSentInstructions, getSOCLogs, getLPLogs } from '../Api/homeApi';
import { BsDownload } from "react-icons/bs"
import CustomEChart, { CustomEChart2, GaugeEChart, GaugeEChart2 } from '../components/Echarts';
import BreadcrumbTrail from '../components/BreadcrumbsTrail';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Close, Download, Info } from '@mui/icons-material';
import PlaceIcon from '@mui/icons-material/Place';
import MapMarker from '../components/mapMarker';
import { BiInfoCircle } from 'react-icons/bi';
import JsonViewer from '../components/JSONViewer';
import { Constants } from '../config/Constants';

const initInfo = {
    deviceId: "loading...",
    lastActivityTime: "loading...",
    status: "loading...",
    soC: 0,
    efficiency: 0,
    storedEnergy: 0,
    address: null
    // capacity: 0
}

let batteryFlowData = []
let dailyStatusData = []
let importGraphData = []
let exportGraphData = []

let socChart = {
    series: [],
    datasets: []
}

let dailyStatusChart = {
    series: [],
    datasets: []
}

let importExportChart = {
    series: [],
    datasets: []
}

let lpChart = {
    series: [],
    datasets: []
}

let personalInfoInit = {
    userId: null,
    manufacturer: null,
    inverterUUID: null,
    locationAddress: null,
    isOctopusAgile: false,
    importMPAN: null,
    importSerialNumber: null,
    exportMPAN: null,
    exportSerialNumber: null,
    octopusAPIKey: null,
    inverterType: null,
    serialNumber: null,
    latLong: null,
}

const formatDate = (date) => momentTZ(date).format("yyyy-MM-DD HH:mm")

const google_key = process.env.REACT_APP_GOOGLE_API_KEY

const textFieldProps = {}

export default () => {
    const classes = useStyles();
    const [containerClass, setContainerClass] = useState("");
    const [drawerContainerClass, setDrawerContainerClass] = useState("");
    const [topData, setTopData] = useState(initInfo);
    const [deviceInfo, setDeviceInfo] = useState({})
    const [personalInfo, setPersonalInfo] = useState(personalInfoInit)
    const [showInverterInfo, setShowInverterInfo] = useState(false);
    // const [showLogModal, setShowLogModal] = useState(false);
    const [showTouModal, setShowTouModal] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: "", type: "" })
    const [loading, setLoading] = useState(false)
    const [graphLoading, setGraphLoading] = useState(false)
    const [lpChartLoading, setLpChartLoading] = useState(false)
    const [from, setFrom] = useState(moment().utc().startOf("day"));
    const [to, setTo] = useState(moment().utc());
    const [chunks, setChunks] = useState(1);
    const [titleAddOn, setTitleAddon] = useState("- Today");
    const [format, setFormat] = useState("HH");
    const [editMode, setEditMode] = useState(false);
    const [timeline, setTimeline] = useState("Today")
    const [prevPersonalInfo, setPrevPersonalInfo] = useState(personalInfoInit)
    const { show, state } = useContext(AppContext)
    const params = useParams()
    const [loc, setLoc] = useState({
        error: false, lat: -34.397, lng: 150.644, message: "Map loading...", showMap: false, loading: true
    })
    const [touDate, setTouDate] = useState(moment().startOf('date'))
    const [logDateRange, setLogDateRange] = useState({
        from: moment().startOf('day'),
        to: moment()
    });
    const [lpDate, setLpDate] = useState({
        date: moment().startOf('date'),
        time: '00:00'
    });
    const [showJsonDialog, setShowJsonDialog] = useState(false);
    const [jsonData, setJsonData] = useState(null);

    const sameDateRange = from.isSame(to, "day")
    const showLPChart = (timeline == "Custom" && sameDateRange) || timeline == "Today" || timeline == "Yesterday"

    const closeAlert = () => setAlert(false)

    const myAlert = (message, type) => {
        setAlert({
            open: true,
            message: message,
            type: type ?? "info"
        })
    }

    const cancelEdit = () => {
        setPersonalInfo(prevPersonalInfo)
        setEditMode(false)
    }

    const changeIsOctopus = () => {
        setPersonalInfo({
            ...personalInfo,
            isOctopusAgile: !personalInfo.isOctopusAgile,
            importMPAN: null,
            importSerialNumber: null,
            exportMPAN: null,
            exportSerialNumber: null,
            octopusAPIKey: null
        })
    }

    const handleShowInverterInfo = () => {
        setShowInverterInfo(true)
    }
    const handleHideInverterInfo = () => {
        setShowInverterInfo(false)
    }

    const changeInverterInfo = (key, e) => {
        // console.log(key, e.target.value)
        setPersonalInfo({
            ...personalInfo,
            [key]: e.target.value
        })
    }

    //#region Functions

    const getfromTo = async (from, to, format, titleAddon, chunks, tag) => {
        setFrom(from);
        setTo(to);
        setChunks(chunks);
        setTitleAddon(titleAddon);
        setFormat(format);
        setTimeline(tag)

        await initGraphs(from, to, state.token, tag);
    };

    const initGraphs = async (from, to, token, currentTimeline) => {
        setGraphLoading(true);
        batteryFlowData = await getBatteryFlow(params.deviceId, from, to, token)

        dailyStatusData = await getInverterDailyStatus(params.deviceId, from, to, token)

        importGraphData = await getImportPrices(from, to, token)
        exportGraphData = await getExportPrices(from, to, token)

        importGraphData = AscDateArray(importGraphData, "periodFrom")
        exportGraphData = AscDateArray(exportGraphData, "periodFrom")

        // const exportArray = exportGraphData?.map(item => item.exportPrice)

        const ImpExpArr = []

        importGraphData?.map((item, index) => {
            ImpExpArr.push([formatDate(item.periodFrom), item.importPrice, exportGraphData[index]?.exportPrice])
        })

        batteryFlowData = AscDateArray(batteryFlowData, "startTime")

        let dailyStatusArr = []
        dailyStatusData.map(item => {
            dailyStatusArr.push([
                formatDate(item.periodFrom),                // xDailyStatus
                item.charge,                // charge
                item.disCharge,             // discharge
                item.solar,             // solar
                item.import,                // yImport
                item.export,                // yExport
            ])
        })

        let socArr = []
        batteryFlowData.sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateA - dateB;
        }).map(item => {
            socArr.push([formatDate(String(item.createdAt).split(".")[0]), item.endingSoC])
        })

        socChart = {
            name: "State of Charge",
            yAxisFormat: "{value}%",
            yTTFormat: ["{value}%"],
            datasets: socArr,
            series: [
                {
                    type: "bar",
                    name: "SoC",
                    fieldType: "float"
                },
            ],
        }

        dailyStatusChart = {
            name: "Daily Status",
            datasets: dailyStatusArr,
            series: [
                {
                    type: "line",
                    name: "Charge",
                    fieldType: "float"
                },
                {
                    type: "line",
                    name: "Discharge",
                    fieldType: "float"
                },
                {
                    type: "line",
                    name: "Solar",
                    fieldType: "float"
                },
                {
                    type: "line",
                    name: "Import",
                    fieldType: "float"
                },
                {
                    type: "line",
                    name: "Export",
                    fieldType: "float"
                },
            ]
        }

        importExportChart = {
            name: "Import-Export Price",
            datasets: ImpExpArr,
            series: [
                {
                    type: "line",
                    name: "Import Prices",
                    fieldType: "float"
                },
                {
                    type: "line",
                    name: "Export Prices",
                    fieldType: "float"
                },
            ],
        }

        await fetchLPData(from);

        setGraphLoading(false);
    }

    const onSubmit = async () => {
        setLoading(true);
        let details = personalInfo
        details.latLong = JSON.stringify({ lat: loc?.lat, long: loc?.lng });
        // console.log("userInfo ==>", userInfo)

        UpdateDeviceInfo(state.token, details, details.userId).then(async res => {
            console.log("device details update res ==>", res)
            setLoading(false);
            fetchPersonalInfo();
            setShowInverterInfo(false)
            setEditMode(false)
            myAlert("Device details updated")
        }).catch(ex => {
            setLoading(false);
            console.log('device details update error ==>', ex)
            setShowInverterInfo(false)
            setEditMode(false)
            myAlert("Error while updating device information", "error")
        });
    }

    const fetchPersonalInfo = async (device) => {
        await getCustomerDetails(device.userId, state.token).then(res => {
            // console.log("res ==>", res)
            if (res.userSettings && res.houseInfo) {
                let details = {
                    manufacturer: device.manufacturer,
                    inverterUUID: device.inverterUUID,
                    locationAddress: device.locationAddress,
                    ...res.userSettings,
                    ...res.houseInfo
                }
                setPrevPersonalInfo(details)
                setPersonalInfo(details)
            }
        })
    }

    useEffect(() => {
        let topComponentData = {}
        const getdata = async () => {
            let device = await getInverter(params.deviceId, state.token);
            let inverterflow = await getInverterFlow(
                params.deviceId,
                state.token
            );

            if (device) {
                setDeviceInfo(device)
                await fetchPersonalInfo(device)
                if (device.latLong) {
                    try {
                        let batteryLoc = JSON.parse(device.latLong);
                        setLoc({
                            lat: batteryLoc.lat,
                            lng: batteryLoc.long,
                            showMap: true,
                            error: false,
                            loading: false,
                            message: ""
                        })
                    } catch {
                        setLoc({
                            ...loc,
                            error: true,
                            loading: false,
                            message: "Failed to load battery map"
                        })
                    }
                } else {
                    setLoc({
                        ...loc,
                        error: false,
                        showMap: false,
                        loading: false,
                        message: "Location not registered"
                    })
                }
            }

            const inverterSocDetails = getSocDetails(inverterflow)        // get soc
            topComponentData = {
                ...device,
                ...inverterSocDetails,
            };

            topComponentData = {
                // ...topComponentData,
                deviceId: topComponentData.inverterUUID,
                lastActivityTime: moment.utc().format("DD MMM yyyy HH:mm"),
                status: topComponentData.desireMode ? String(topComponentData.desireMode).toUpperCase() : "INACTIVE",
                soC: topComponentData.soC && Math.round(topComponentData.soC),
                efficiency: topComponentData.efficiencyPercentage,
                storedEnergy: 0,
                address: topComponentData.locationAddress
            };
        };
        if (state.token) {
            getdata().then(() => {
                // console.log("topComponentData ==>", topComponentData)
                setTopData(topComponentData);
            });
            getfromTo(from, to, format, titleAddOn, chunks, timeline);
            initGraphs(from, to, state.token, timeline);
        }
    }, [state.token]);

    useEffect(() => {
        if (show) {
            setContainerClass(null);
            setDrawerContainerClass(null);
        } else {
            setContainerClass(classes.containeropen);
            setDrawerContainerClass(classes.drawercontaineropen);
        }
    }, [show]);

    const soc = topData.soC ? topData.soC : 0
    const efficiency = topData.efficiency ? parseFloat(topData.efficiency) * 100 : 0

    const today = moment().format("yyyy-MM-DD")

    const getSocCsv = () => {
        let name = params.deviceId + "-optimization" + "-" + today
        convertJsonToCsv(batteryFlowData, name)
    }

    const getImportPricesCsv = () => {
        let name = "Export-Prices" + "-" + today
        convertJsonToCsv(importGraphData, name)
    }
    const getExportPricesCsv = () => {
        let name = "Import-Prices" + "-" + today
        convertJsonToCsv(exportGraphData, name)
    }

    const formatDeviceLogs = (logs) => {
        const formattedRows = [];
        let previousOptDate = null;

        logs.forEach(log => {
            // Add empty row if optimization date changes
            if (previousOptDate && previousOptDate !== log.optimisationDate) {
                formattedRows.push({
                    'Optimisation Date': '',
                    'Timestamp': '',
                    'Import Prices': '',
                    'Export Prices': '',
                    'Usage Forecast': '',
                    'SOC': '',
                    'Status': '',
                    'Requested Date': ''
                });
            }

            const requestObj = JSON.parse(log.request);
            const responseObj = JSON.parse(log.response);

            // Get all timestamps from SOC object
            const timestamps = Object.keys(responseObj.SOC);

            // Create a row for each timestamp
            timestamps.forEach((timestamp, index) => {
                // Convert Unix timestamp (milliseconds) to UTC string
                const utcDate = new Date(parseInt(timestamp)).toISOString();

                formattedRows.push({
                    'Optimisation Date': log.optimisationDate,
                    'Timestamp': utcDate,
                    'Import Prices': requestObj.import_prices[index],
                    'Export Prices': requestObj.export_prices[index],
                    'Usage Forecast': requestObj.house_load[index],
                    'SOC': responseObj.SOC[timestamp],
                    'Status': responseObj.Status[timestamp],
                    'Requested Date': log.requestedDate
                });
            });

            previousOptDate = log.optimisationDate;
        });

        return formattedRows;
    };

    const handleLogDownload = async () => {
        setLoading(true);
        try {
            const response = await getDeviceLogs(
                params.deviceId,
                logDateRange.from,
                logDateRange.to,
                state.token
            );

            const formattedLogs = formatDeviceLogs(response);
            const fileName = `${params.deviceId}-logs-${moment().format('YYYY-MM-DD')}`;
            convertJsonToCsv(formattedLogs, fileName);
            setShowLogModal(false);
        } catch (error) {
            console.error('Error downloading logs:', error);
            myAlert('Failed to download logs', 'error');
        }
        setLoading(false);
    };

    const handleSOCDownload = async () => {
        setLoading(true);
        try {
            const response = await getSOCLogs(
                params.deviceId,
                touDate,
                touDate,
                state.token
            );
            let reqbody = response?.requestBody;
            if (!reqbody) {
                reqbody = response;
            }

            setJsonData(JSON.stringify(JSON.parse(reqbody, null, 2)));
            setShowJsonDialog(true);
            setShowLogModal(false);
        } catch (error) {
            console.error('Error downloading soc:', error);
            myAlert('Failed to download soc', 'error');
        }
        setLoading(false);
    }

    const handleInstructionsDownload = async () => {
        setLoading(true);
        try {
            // Replace with your actual API call
            const response = await getDeviceSentInstructions(
                params.deviceId,
                logDateRange.from,
                logDateRange.to,
                state.token
            );

            const fileName = `${params.deviceId}-instructions-${moment().format('YYYY-MM-DD')}`;
            convertJsonToCsv(response, fileName);
            setShowLogModal(false);
        } catch (error) {
            console.error('Error downloading instructions:', error);
            myAlert('Failed to download instructions', 'error');
        }
        setLoading(false);
    };

    const fetchLPData = async (from) => {
        setLpChartLoading(true)

        const response = await getLPLogs(params.deviceId, from, moment(lpDate.time, "HH:mm"), state.token);
        if (response && response.result) {
            try {
                const result = JSON.parse(response.result);
                const socResData = result.SOC;

                // Sort timestamps in ascending order
                const chartData = Object.entries(socResData)
                    .sort(([timestampA], [timestampB]) => parseInt(timestampA) - parseInt(timestampB))
                    .map(([timestamp, value]) => {
                        const date = moment(parseInt(timestamp)).utc();
                        const timeStr = moment(date).format("YYYY-MM-DD HH:mm")
                        return [timeStr, value];
                    });

                lpChart = {
                    name: "Load Profile SOC",
                    yAxisFormat: "{value}%",
                    yTTFormat: ["{value}%"],
                    datasets: chartData,
                    series: [
                        {
                            type: "bar",
                            name: "SOC",
                            fieldType: "float"
                        }
                    ]
                };
                setLpChartLoading(false)
            } catch (ex) {
                setLpChartLoading(false)
            }
        } else {
            setLpChartLoading(false)
        }
    };

    //#endregion

    //#region components

    const SocIcon = () => (
        <Button
            variant={'outlined'}
            size="small"
            disabled={graphLoading}
            onClick={getSocCsv}
        >
            <Download
                size={14}
            />
        </Button>
    )

    const PricesIcon = () => (
        <Box className={classes.csvButtons} >
            <Button
                variant={'outlined'}
                disabled={graphLoading}
                size="small"
                startIcon={
                    <Download
                        size={14}
                    />
                }
                onClick={getImportPricesCsv}>
                Import

            </Button>
            <Button
                variant={'outlined'}
                disabled={graphLoading}
                size="small"
                startIcon={
                    <Download
                        size={14}
                    />
                }
                onClick={getExportPricesCsv}>
                Export

            </Button>
        </Box>
    )

    const SelectTimestamp = () => {
        return (
            <Stack direction="row" spacing={2} alignItems="flex-end">
                <FormControl variant='standard' sx={{ minWidth: 120 }}>
                    <InputLabel id="lp-time-select">Time</InputLabel>
                    <Select
                        labelId="lp-time-select"
                        id="lp-simple-select"
                        value={lpDate.time}
                        onChange={(event) => {
                            setLpDate({ ...lpDate, time: event.target.value });
                        }}
                    >
                        {Constants.timeForLp.map((item) => (
                            <MenuItem
                                key={item}
                                style={{ fontSize: 14 }}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Button
                    variant="contained"
                    onClick={() => fetchLPData(from)}
                    disabled={graphLoading && lpChartLoading}
                    sx={{ height: 36 }}
                >
                    {(graphLoading || lpChartLoading) ? <CircularProgress size={20} /> : 'Submit'}
                </Button>
            </Stack>
        )
    }
    //#endregion

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event, val) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Stack direction="row">
                <Box
                    height="100%"
                    className={clsx(classes.drawercontainer, drawerContainerClass)}
                >
                    <Drawer show={show} location="batteries" />
                </Box>

                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    open={alert.open}
                    autoHideDuration={6000}
                    onClose={closeAlert}
                >
                    <Alert
                        onClose={closeAlert}
                        severity={alert.type ?? "info"}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {alert.message}
                    </Alert>
                </Snackbar>

                <Box className={clsx(classes.container, containerClass)}>
                    <BreadcrumbTrail sx={{ my: 2 }} />
                    <Box className={classes.main}>
                        <Box className={classes.rootStack} >
                            <Card className={classes.infoCard} style={{ flex: 1 }}>
                                <GaugeEChart
                                    color={['#42c991', '#e5e5e5']}
                                    data={soc}
                                />
                                {/* <Typography className={classes.charge}>{soc}%</Typography> */}
                                <Typography
                                    className={classes.sub}
                                    mt={-4}
                                    variant='h6'
                                    color="#d1d4d7"
                                >
                                    State of Charge
                                </Typography>
                            </Card>
                            <Card className={classes.infoCard} style={{ flex: 1 }}>
                                <GaugeEChart2
                                    value={efficiency}
                                />
                                {/* <Typography className={classes.charge}>{soc}%</Typography> */}
                                <Typography
                                    className={classes.sub}
                                    mt={-1}
                                    variant='h6'
                                    color="#d1d4d7"
                                >
                                    Battery Efficiency
                                </Typography>
                            </Card>
                        </Box>
                        <Box className={classes.rootStack} style={{ margin: "15px 0px" }}>
                            {/* <Divider variant="vertical" /> */}
                            {Object.keys(topData).map((key, index) => {
                                let color = "#000"
                                let itemValue = topData[key]
                                let dataItem = data1.find(d => d.accessor === key)
                                if (dataItem?.color) {
                                    color = dataItem.color(itemValue)
                                }
                                if (dataItem?.format) {
                                    itemValue = dataItem.format(itemValue)
                                }
                                return (
                                    <>
                                        {dataAddOn1[key] && <Card className={classes.infoCard} style={{ height: 200 }}>
                                            <Box className={classes.icon} >
                                                {dataAddOn1[key].icon}
                                            </Box>
                                            <Box className={classes.data}>
                                                <Typography
                                                    className={classes.title}
                                                    style={{ color: color }}
                                                    variant='h6'
                                                >
                                                    {itemValue}
                                                </Typography>
                                                <Typography className={classes.sub} fontSize="md" fontWeight="600" color="#d1d4d7">
                                                    {dataItem.title}
                                                </Typography>
                                            </Box>
                                        </Card>
                                        }
                                    </>
                                )
                            }
                            )}
                            <Card className={classes.infoCard} style={{ height: 200 }}>
                                <Box className={classes.icon} >
                                    <BiInfoCircle size={60} color="#d1d4d7" />
                                </Box>
                                <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                                    <Button onClick={handleShowInverterInfo} >
                                        More Device Details
                                    </Button>
                                </Box>
                                <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                                    <Button onClick={() => setShowTouModal(true)} >
                                        Log Info
                                    </Button>
                                </Box>
                            </Card>
                        </Box>
                        <Timeline
                            defaultSetRange={'Today'}
                            data={buttonData}
                            getfromTo={getfromTo}
                        />

                        <Container title={`Battery State of Charge [%] ${titleAddOn}`} sideComponent={<SocIcon />} >
                            <CustomEChart2
                                data={socChart}
                                loading={graphLoading}
                                timeline={timeline}
                                max={100}
                            />
                        </Container>

                        <Container title={`Import-Export Prices ${titleAddOn}`} sideComponent={<PricesIcon />} >
                            <CustomEChart2
                                loading={graphLoading}
                                timeline={timeline}
                                data={importExportChart}
                            />
                        </Container>

                        <Container title={`Daily Status ${titleAddOn}`} >
                            <CustomEChart2
                                data={dailyStatusChart}
                                loading={graphLoading || lpChartLoading}
                                timeline={timeline}
                            />
                        </Container>

                        {showLPChart && (
                            <Container
                                title={`LP Optimisation Chart ${timeline === 'Custom' ? `- Custom (${from.format("D MMM")})` : titleAddOn}`}
                                sideComponent={<SelectTimestamp />}
                            >
                                <CustomEChart2
                                    data={lpChart}
                                    loading={graphLoading}
                                    timeline={timeline}
                                    max={100}
                                />
                            </Container>
                        )}

                        <Container
                            title="Battery map"
                        >
                            <Box style={{ height: "42vh", position: "relative" }} >
                                {!loc.showMap && (
                                    <Box className={classes.center}>
                                        <Stack direction={"row"} spacing={1}>
                                            {loc.loading && <CircularProgress size={18} thickness={5} />}
                                            <Typography color={loc.error ? "red" : "InfoText"} >{loc.message}</Typography>
                                        </Stack>
                                    </Box>
                                )}

                                <GoogleMapReact
                                    defaultZoom={10}
                                    bootstrapURLKeys={{
                                        key: google_key,
                                    }}
                                    options={{
                                        draggable: false,
                                        draggableCursor: false,
                                        fullscreenControl: false,
                                        streetViewControl: false,
                                    }}
                                    defaultCenter={{ "lat": 51.8801406, "lng": -2.2939207, }}
                                    style={{ height: "42vh", display: loc.showMap ? "block" : "none" }}
                                >
                                    <MapMarker address={topData.address} />
                                </GoogleMapReact>

                                <Dialog open={showInverterInfo} onClose={handleHideInverterInfo} >
                                    {/* {console.log("personalInfo ==>", personalInfo)} */}
                                    <Box className={classes.inverterInfoRoot}>
                                        <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center", mb: 2 }} >
                                            <Stack direction={"row"} spacing={1} >
                                                <Typography variant='h5' >Device Info</Typography>
                                                <Button onClick={() => setEditMode(!editMode)} >{editMode ? "Cancel" : "Edit"}</Button>
                                            </Stack>
                                            <IconButton onClick={handleHideInverterInfo} >
                                                <Close />
                                            </IconButton>
                                        </Stack>
                                        <Grid container rowSpacing={3} columnSpacing={5} >
                                            <Grid item xs={4}>
                                                <div>
                                                    <Typography fontSize={14} color={"GrayText"}>Device Manufacturer</Typography>
                                                    <FormControl variant="standard" fullWidth >
                                                        <Select
                                                            className={classes.textfield}
                                                            fullWidth
                                                            sx={{ pointerEvents: editMode ? "unset" : "none" }}
                                                            id="manufacturer"
                                                            value={personalInfo.manufacturer}
                                                            onChange={(val) => changeInverterInfo("manufacturer", val)}
                                                            IconComponent={() => null}
                                                            size='small'
                                                        >
                                                            <MenuItem value={"Soltaro"}>Soltaro</MenuItem>
                                                            <MenuItem value={"Solax"}>Solax</MenuItem>
                                                            <MenuItem value={"Tesla"}>Tesla</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <div>
                                                    <Typography fontSize={14} color={"GrayText"}>Soltaro Secret Key</Typography>
                                                    <TextField
                                                        fullWidth
                                                        sx={{ pointerEvents: editMode ? "unset" : "none" }}
                                                        variant="standard"
                                                        className={classes.textfield}
                                                        size="small"
                                                        value={personalInfo.inverterUUID}
                                                        onChange={(val) => changeInverterInfo("inverterUUID", val)}
                                                        inputProps={textFieldProps}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div>
                                                    <Typography fontSize={14} color={"GrayText"}>Device Location</Typography>
                                                    <TextField
                                                        fullWidth
                                                        sx={{ pointerEvents: editMode ? "unset" : "none" }}
                                                        variant="standard"
                                                        className={classes.textfield}
                                                        size="small"
                                                        value={personalInfo.locationAddress}
                                                        onChange={(val) => changeInverterInfo("locationAddress", val)}
                                                        inputProps={textFieldProps}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    disabled={!editMode}
                                                    labelPlacement='start'
                                                    control={
                                                        <Switch
                                                            onChange={changeIsOctopus}
                                                            checked={personalInfo.isOctopusAgile}
                                                        />
                                                    }
                                                    label="OctopusAgile"
                                                />
                                            </Grid>
                                            {personalInfo.isOctopusAgile &&
                                                <>
                                                    <Grid item xs={6}>
                                                        <div>
                                                            <Typography fontSize={14} color={"GrayText"}>Import MPAN</Typography>
                                                            <TextField
                                                                fullWidth
                                                                sx={{ pointerEvents: editMode ? "unset" : "none" }}
                                                                variant="standard"
                                                                className={classes.textfield}
                                                                size="small"
                                                                value={personalInfo.importMPAN}
                                                                onChange={(val) => changeInverterInfo("importMPAN", val)}
                                                                inputProps={textFieldProps}
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div>
                                                            <Typography fontSize={14} color={"GrayText"}>Import SNo</Typography>
                                                            <TextField
                                                                fullWidth
                                                                sx={{ pointerEvents: editMode ? "unset" : "none" }}
                                                                variant="standard"
                                                                className={classes.textfield}
                                                                size="small"
                                                                value={personalInfo.importSerialNumber}
                                                                onChange={(val) => changeInverterInfo("importSerialNumber", val)}
                                                                inputProps={textFieldProps}
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div>
                                                            <Typography fontSize={14} color={"GrayText"}>Export MPAN</Typography>
                                                            <TextField
                                                                fullWidth
                                                                sx={{ pointerEvents: editMode ? "unset" : "none" }}
                                                                variant="standard"
                                                                className={classes.textfield}
                                                                size="small"
                                                                value={personalInfo.exportMPAN}
                                                                onChange={(val) => changeInverterInfo("exportMPAN", val)}
                                                                inputProps={textFieldProps}
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div>
                                                            <Typography fontSize={14} color={"GrayText"}>Export SNo</Typography>
                                                            <TextField
                                                                fullWidth
                                                                sx={{ pointerEvents: editMode ? "unset" : "none" }}
                                                                variant="standard"
                                                                className={classes.textfield}
                                                                size="small"
                                                                value={personalInfo.exportSerialNumber}
                                                                onChange={(val) => changeInverterInfo("exportSerialNumber", val)}
                                                                inputProps={textFieldProps}
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div>
                                                            <Typography fontSize={14} color={"GrayText"}>Octopus API Key</Typography>
                                                            <TextField
                                                                fullWidth
                                                                sx={{ pointerEvents: editMode ? "unset" : "none" }}
                                                                variant="standard"
                                                                className={classes.textfield}
                                                                size="small"
                                                                value={personalInfo.octopusAPIKey}
                                                                onChange={(val) => changeInverterInfo("octopusAPIKey", val)}
                                                                inputProps={textFieldProps}
                                                            />
                                                        </div>
                                                    </Grid>
                                                </>}
                                            {editMode &&
                                                <Grid item xs={12}>
                                                    <Stack direction={"row"} spacing={4} >
                                                        <Button variant="contained" sx={{ backgroundColor: "gray" }} onClick={cancelEdit}>Cancel</Button>
                                                        <Button variant="contained" type="submit" onClick={onSubmit}>Save changes</Button>
                                                    </Stack>
                                                </Grid>
                                            }
                                        </Grid>

                                    </Box>
                                    <style>{`
                                    .${classes.inverterInfoRoot} .MuiInput-root::before {
                                            border-bottom: ${editMode ? "1px solid rgba(0, 0, 0, 0.42)" : "none !important"}
                                        }
                                `}</style>
                                </Dialog>
                            </Box>
                        </Container>
                    </Box>
                </Box>
            </Stack >
            <Dialog maxWidth="md" open={showTouModal} onClose={() => setShowTouModal(false)}>
                <Box sx={{ p: 3, minWidth: 400 }}>
                    <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center", mb: 3 }}>
                        <Typography variant="h6">Download Device Data</Typography>
                        <IconButton onClick={() => setShowTouModal(false)}>
                            <Close />
                        </IconButton>
                    </Stack>

                    <Stack spacing={3}>
                        <TextField
                            label="Date"
                            type="date"
                            value={touDate.format('YYYY-MM-DD')}
                            onChange={(e) => setTouDate(moment(e.target.value))}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />

                        <Stack direction="row" spacing={3} sx={{ justifyContent: "flex-end" }}>
                            <Button
                                variant="contained"
                                onClick={handleSOCDownload}
                                disabled={loading}
                                startIcon={loading ? <CircularProgress size={20} /> : ""}
                                sx={{ flex: 1, minWidth: 200 }}
                            >
                                Show TOU
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Dialog>
            {/* <Dialog maxWidth="md" open={showLogModal} onClose={() => setShowLogModal(false)}>
                <Box sx={{ p: 3, minWidth: 700 }}>
                    <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center", mb: 3 }}>
                        <Typography variant="h6">Download Device Data</Typography>
                        <IconButton onClick={() => setShowLogModal(false)}>
                            <Close />
                        </IconButton>
                    </Stack>

                    <Stack spacing={3}>
                        <TextField
                            label="From Date"
                            type="datetime-local"
                            value={logDateRange.from.format('YYYY-MM-DDTHH:mm')}
                            onChange={(e) => setLogDateRange({
                                ...logDateRange,
                                from: moment(e.target.value)
                            })}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />

                        <TextField
                            label="To Date"
                            type="datetime-local"
                            value={logDateRange.to.format('YYYY-MM-DDTHH:mm')}
                            onChange={(e) => setLogDateRange({
                                ...logDateRange,
                                to: moment(e.target.value)
                            })}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />

                        <Stack direction="row" spacing={3} sx={{ justifyContent: "center" }}>
                            <Button
                                variant="contained"
                                onClick={handleLogDownload}
                                disabled={loading}
                                startIcon={loading ? <CircularProgress size={20} /> : <Download />}
                                sx={{ flex: 1, minWidth: 200 }}
                            >
                                Download Logs
                            </Button>

                            <Button
                                variant="contained"
                                onClick={handleInstructionsDownload}
                                disabled={loading}
                                startIcon={loading ? <CircularProgress size={20} /> : <Download />}
                                sx={{ flex: 1, minWidth: 200 }}
                            >
                                Download Instructions
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSOCDownload}
                                disabled={loading}
                                startIcon={loading ? <CircularProgress size={20} /> : ""}
                                sx={{ flex: 1, minWidth: 200 }}
                            >
                                Show TOU
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Dialog> */}
            <Dialog
                open={showJsonDialog}
                onClose={() => setShowJsonDialog(false)}
                maxWidth="md"
                fullWidth
            >
                <Box sx={{ p: 3 }}>
                    <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center", mb: 3 }}>
                        <Typography variant="h6">TOU</Typography>
                        <IconButton onClick={() => setShowJsonDialog(false)}>
                            <Close />
                        </IconButton>
                    </Stack>

                    <Box sx={{
                        bgcolor: '#1e1e1e',
                        p: 2,
                        borderRadius: 1,
                        maxHeight: '70vh',
                        overflow: 'auto',
                        fontFamily: 'monospace',
                        fontSize: '14px',
                        lineHeight: '1.5'
                    }}>

                        <JsonViewer jsonData={jsonData} />

                    </Box>
                </Box>
            </Dialog>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: 60,
        width: "80%",
        transition: "width 0.3s ease-out",
        padding: 0
    },
    main: {
        display: "flex",
        flexDirection: "column",
        gap: 30,
        margin: 40,
        marginTop: 0
    },
    rootStack: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginTop: 0,
        marginBottom: -10,
        width: "100%",
        [theme.breakpoints.down("lg")]: {
            padding: "20px 0px",
            marginBottom: -5
        },
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            padding: "20px 10px",
            marginBottom: -5
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: 0,
            padding: "2px"
        }
    },
    inverterInfoRoot: {
        padding: 30,
    },
    containeropen: {
        width: "100%",
    },
    drawercontainer: {
        width: "20%",
        transition: "width 0.1s ease-out",
    },
    drawercontaineropen: {
        width: "0%",
        zIndex: "100",
    },
    textfield: {
        // backgroundColor: "#fff",
        width: "max-content"
    },
    topInfo: {
        width: "100%",
        paddingRight: 36,
        paddingLeft: 36,
        // marginLeft: 40,
        display: "flex",
        flex: 1,
        flexDirection: "row"
    },
    infoCard: {
        padding: 15,
        borderRadius: 0,
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        textAlign: "center",
    },
    secTitle: {
        fontSize: 15,
        fontWeight: "bold"
    },
    icon: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end"
    },
    data: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        textAlign: "center"
    },
    title: {
        // fontSize: 16,
    },
    sub: {
        // fontSize: 16
    },
    csvButtons: {
        display: "flex",
        flexDirection: "row",
        gap: 5
    },
    tooltip: {
        color: "#fff",
        backgroundColor: "rgba(0, 0, 0, 0.85)",
        padding: 5,
        textAlign: "center",
        borderRadius: 8,
        boxShadow: "0.3em 0.3em 1em rgb(0 0 0 / 60%)"
    },
    ttFont: {
        fontSize: 12,
        fontWeight: "bold"
    },
    charge: {
        position: "absolute",
        top: "50%",
        left: "50%",
        fontWeight: "bold",
        fontSize: 25,
        transform: "translate(-50%, -10%)",
    },
    center: {
        // height: "100%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -10%)",
    }
}));
